#pagination-next.disabled,
#pagination-previous.disabled {
  pointer-events: none;
}

.mouse {
  cursor: pointer;
}

.page-link {
  width: 28px;
  height: 28px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  line-height: 1;
}