.faq-sub-title {
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #2C2C2C;
    opacity: 0.8;
}

.faq-title {
    text-align: center;
    font-family: 'satoshi-medium';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
}

.faq-search-wraper input {
    outline-offset: unset;
    -webkit-appearance: textfield;
    border: 0;
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: start;
    color: #555555;
}

.faq-search-wraper input:focus-visible {
    border: 0;
    outline: none;
}

.faq-search-wraper input:focus {
    border: 0;
    outline: none;
}

.faq-search-wraper {
    width: 440px;
    height: 60px;
    border: 1px solid rgba(85, 85, 85, 0.2);
    border-radius: 4px;
    column-gap: 1rem;
}

.faq-search-wraper .faq-search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.accordion-button:not(.collapsed) {
    color: #000000 !important;
    /* background-color: #e7f1ff; */
    /* box-shadow: inset 0 -1px 0 rgba(0,0,0,.125); */
    font-weight: bold;
}

.accordion-item {
    background-color: #f4f4f4;
    border-bottom: solid 1px #dddddd !important;
    border-radius: 10px !important;
}
.accordion-item .accordion-button{
    border: none !important;
    
}

.accordion {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.accordion-button:not(.collapsed) {
    color: black !important;
}

.accordion-button:not(.collapsed)::after .accordion-button:not(.collapsed)::after {
    background-image: none !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;

}

.custom-search {
    width: 100% !important;
}

#search_input-label[data-shrink=true],
.custom-search fieldset {
    display: none !important;
}

span.faq-search-icon:hover {
    background-color: #f4f4f4;
    transition: all 0.2s ease-in-out;
}

.accordion-header {
    --bs-accordion-border-width: 0px !important;
}

@media (max-width:600px){
    .faq-title{
        font-size: 37px;
        font-weight: 700;
        line-height: 50px;  
    }
    .accordion-button::after{
        width: 0.9rem;
        height: 0.9rem;
        background-size: 0.9rem;  
    }
    
}