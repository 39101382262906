.mfc-container {
    background-color: #F6F6F7;
}

.mfc-body {
    position: relative;
    max-width: 1052px;
    width: 100%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.mfc-container header {
    background-color: #FFFFFF;
    width: 100%;
}

.mfc-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    max-width: 1052px;
    margin: auto;
}

.mfc-left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
}

.mfc-left img {
    width: 40px;
    height: 40px;
}

.mfc-left p {
    width: 142px;
    height: 24px;
    font-family: "satoshi-medium";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #2C2E4B;
    flex: none;
    order: 1;
    white-space: nowrap;
    flex-grow: 0;
    margin: 0;
}

.mfc-right button {
    width: 173px;
    height: 42px;
    background: #041220;
    border-radius: 8px;
    padding: 5px 12px;
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

.mfc-main-l {
    box-sizing: border-box;
    width: 666px;
    background: #FFFFFF;
    border: 1px solid #E6E9EF;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mfc-main-r {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    /* position: fixed;
    right: 0;
    top: 0; */
}

.mfc-title {
    width: 100%;
    font-family: 'satoshi-medium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: normal;
    text-align: start;
    color: #1B273E;
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid #E6E9EF;
}

.mfc-w-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mfc-w-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding: 20px;
    max-height: 486px;
    overflow-y: auto; */
}
.mfcwc-title{
    padding: 20px;
    width: 100%;
}
.mfc-rows{
    padding: 20px;
    max-height: 547px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

h4.mfc-wc-title {
    font-family: 'satoshi-medium';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: normal;
    color: #3B38A8;
    margin-bottom: 8px;
}

p.mfc-wc-title {
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #21345A;
    margin: 0;
}

.mfc-divider {
    width: 100%;
    height: 1px;
    display: block;
    background-color: #E6E9EF;
    margin:0;
}

.mfc-prof-card {
    box-sizing: border-box;
    width: 326px;
    height: 204px;
    background: #FFFFFF;
    border: 1px solid #E6E9EF;
    border-radius: 16px;
    flex: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mfcp-top {
    display: flex;
    flex-direction: row;
    align-items:0p center;
    gap: 20px;
}

.mfc-prof {
    box-sizing: border-box;
    width: 72px;
    height: 72px;
    border: 1px solid #E6E9EF;
    border-radius: 64px;
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mfc-credit p {
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #4B596D;
    margin-bottom: 0px;
}

.mfc-credit h4 {
    font-family: 'satoshi-medium';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #1B273E;
    margin: 0;
}

.mfc-prof-card button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 8px;
    gap: 8px;
    border: 1px solid #E6E9EF;
    border-radius: 8px;
    font-family: 'satoshi-medium';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4B596D;
    background: #ffffff;
}

.mfc-prof-card.neechy h4 {
    font-family: 'satoshi-medium';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    text-align: center;
    color: #1B273E;
}

.mfc-prof-card.neechy p {
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: #4B596D;
}

.mfcp-bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.mfcp-bottom img {
    width: 100%;
}

@media (max-width:800px){
    .mfc-body{
        width: 100%;
        padding: 12px 20px 20px 20px;
        flex-direction: column;
        max-width: 100%;
        gap: 12px;
    }
    .mfc-main-l{
        width: 100%;
        margin-top: 214px;
    }
    .mfc-prof-card.uper{
        width: calc(100% - 40px);
        position: absolute;
        z-index: 1;
        top: 12px;
    }
    .mfc-prof-card{
        width: 100%;
    }
    .mfc-header{
        max-width: 100%;
    }
}

@media (max-width:530px){
/* header  */
.mfc-header{
    padding: 12px;
}

.mfc-right button{
    width: 95px;
    height: 32px; 
    padding: 5px 10px;
    font-size: 14px;
    line-height: normal;
}
.mfc-left img{
    width: 36px;
    height: 36px; 
}
.mfc-left{
    gap: 8px; 
}
.mfc-left p{
    width: auto;
    height: auto;
    font-size: 14px;
    line-height: normal;  
}
.mfc-body{
    padding: 12px;
}
.mfc-prof{
    width: 52px;
    height: 52px;
}
.mfc-prof img{
    width: 26px;
    height: 26px;
}
.mfc-credit p{
    font-size: 12px;
}
.mfc-credit h4{
    font-size: 18px;
}
.mfc-prof-card button{
    font-size: 14px;
    line-height: normal;
}
.mfc-prof-card button svg{
    width: 20px;
    height: 24px;
}
.mfc-title{
    font-size: 20px;
    padding: 12px; 
}
.mfc-w-card{
    /* padding: 12px; */
}
.mfcwc-title{
    padding: 12px;
    width: 100%;
}
.mfc-rows{
    padding: 12px;
}
h4.mfc-wc-title{
    font-size: 18px;
}
.mfc-prof-card.uper{
    width: calc(100% - 24px);
}
.mfc-prof-card{
    padding: 12px;
}
}

button.copy-btn.active::before {
    content: 'copied';
    background: mediumseagreen;
    padding: 4px 1rem;
    border-radius: 4px;
    color: white;
    position: absolute;
    top: -4px;
    right: 0;
    transform: translateY(-100%);
}