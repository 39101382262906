.blogs-text-wraper img {
    width: 100%;
}

@media (max-width: 1200px) and (min-width: 420px) {
    .single-blog-cards-bottom .inner-sm-heading {
        width: 100% !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}