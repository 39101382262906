.custom-rocket {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9999999;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  filter: drop-shadow(2px 4px 14px rgba(0, 0, 0, 0.15));
}

.custom-rocket.active {
  opacity: 0.5;
}

.custom-rocket.active:hover {
  opacity: 1;
}

@media screen and (max-width: 610px) {
  .custom-rocket.active {
    opacity: 1;
  }
}