::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: gray;
}

body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #F5F5F5;
}

.scrollbar-danger {
  scrollbar-color: #FF3547 #F5F5F5;
}

.App {
  text-align: center;
}

img {
  -webkit-user-drag: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body * {
  font-family: Arial, Helvetica, sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.fill-yellow path{
fill: #F0B74C !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mt-6rem{
  margin-top: 10rem;
}
html {
  font-size: 16px;
}

body {
  background-color: #FFFFFF;
}

.bg-light-primary {
  background: #FFFFFF;
}

/* .h89vh{
  height: 89vh;
} */

.app-btn {
  background: linear-gradient(270.19deg, #6F73BC 0.17%, #3E3C93 99.86%);
  border-radius: 50px;
  color: #FFFFFF !important;
}

.app-btn:hover {
  color: white;
}

.app-btn:focus {
  color: white;
}


.btn-play {
  background-color: black;
  color: white;
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 6px;
  position: relative;
}

.btn-play::after {
  content: "";
  position: absolute;
  top: 0;
  left: 40%;
  background: linear-gradient(to right, white 100%, white 50%);
  opacity: 0.9;
}

.text-justify {
  text-align: justify !important;
}

.plan-txt {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: normal;
  color: #21345A;
}

.plan-txt-white {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: normal;
  color: #FFFFFF;
  opacity: 0.6;
}

.plan-txt-md {
  font-family: 'satoshi-medium';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: normal;
  color: #21345A;
}

.plan-txt-sm-black {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  color: #21345A;
  /* opacity: 0.8; */
}

.plan-txt-sm-black a {
  font-family: 'satoshi-medium';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  color: #21345A;
  text-decoration: none;
}

.plan-txt-sm-white {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.8;
}

.plan-txt-xsm {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  color: #21345A;

}

.plan-txt-xsm2 {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #21345A;

}

.text-cut-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-cut-3-real {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-cut-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.gap-md-1rem {
  column-gap: 1rem;
}

.black-light {
  color: #555555 !important;
}

.button-upper-sm-txt {
  /* font-family: 'Product Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: normal;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  margin: 0;
}

.button-lower-sm-txt {
  /* font-family: 'Product Sans'; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: normal;
  color: #FFFFFF;
  margin: 0;
}

.blogs-heading {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #131E34;
}

.blogs-description-txt {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #21345A;
}

.big-card-head {
  font-family: 'satoshi-medium';
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: normal;
  color: #131E34;
}

.blogs-share {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #2C2C2C;

}

.big-card-date {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
}

.inner-sm-heading {
  font-family: 'satoshi-medium';
  font-size: 17px;
  font-weight: 700;
}

.inner-sm-description {
  font-family: 'satoshi';
  font-size: 15px;
  color: #21345A;
}

.card-radius-bg {
  background-color: #F7F7F7;
  border-radius: 40px;
}

/* owl-carousel */
.owl-carousel .owl-item img {
  display: unset;
  width: unset;
}

.item {
  box-shadow: none;
}

/* Hero Section  */




.app-titleh {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: normal;
  color: #131E34;
}
.app-titleh svg{
  margin-top: -4px;
}

.hero-heading {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 100%;
  color: #131E34;
}

span.hero-heading-blue {
  color: #5E40D4;
}

.hero-sub-heading {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 100%;
  color: #131E34;
}

.hero-buttons-applink a.app-btn {
  width: 284px;
  height: 72px;
  background: linear-gradient(90deg, #152331 0%, #000000 100%);
  border-radius: 68px;
  display: flex;
  padding: 13px 1px;
  gap: 19px;
  justify-content: center;
}

.card-inner-title {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #131E34;
}



/* Hero section Left Portion */





.hero-left {
  row-gap: 1.5rem;
  width: 38%;
}

.hero-left-lower {
  column-gap: 1.5rem;
}

.hero-left-lower p {
  margin: 0;
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
}

.hero-left-lower span {
  font-family: 'satoshi-medium';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;

}

.xborder-card {
  border-left: 1px solid #4F4D90;
  border-right: 1px solid #4F4D90;
  padding: 0rem 3rem;
}

.heading-48px-white {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 126%;
  color: #FFFFFF;
  margin: 0;
}

.card-inner-heading {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  color: #131E34;
}


/* Hero Section right Portion  */

.hero-right{
  width: 62%;
}

.hero-right img {
  width: 100%;
}

.hero-right video {
  width: 100%;
}


/* Introduction section */

/* Introduction section  Left portion*/

.introduction-sec-homepg {
  position: relative;
}

.intro-heading {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 120%;
  color: #FFFFFF;
  margin: 0;
}

.intro-sub-heading {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 120%;
  color: #FFFFFF;
  opacity: 0.6;
  margin: 0;
}

.heading-64px {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 100%;
  color: #131E34;
}

.intro-wraper {
  background-color: #191A30;
}

.intro-left img {
  mix-blend-mode: luminosity;
  opacity: unset;
  width: 100%;
}

.intro-mid {
  padding: 3.5rem 5rem;
}

.intro-right {
  padding: 4rem;
  width: 29rem;
  position: absolute;
  right: 0;
}

.camera-lense {
  position: absolute;
  top: 18px;
  z-index: 9999;
  margin: auto;
  width: max-content;
  left: 0;
  right: 0;
}




/* App features Section */


.features-card-wraper {
  align-self: center;
  width: 100%;
  background-color: white;
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.features-logo1 {
  width: 100%;
}

.feature-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background: #FFFFFF;
  width: 33.333%;
  min-width: 285px;
}


.feature-card-logo{
box-sizing: border-box;
width: 64px;
height: 64px;
border: 1px solid #D7DCE3;
border-radius: 48px;
display: flex;
align-items: center;
justify-content: center;
}
.feature-card-logo img {
 width: 32px;
 height: 32px;
}
#app-features-section .feature-card .app-titleh{
  font-size: 24px;
  font-family: 'cabinetGrotesk-bold';
  margin: 0;
  margin-bottom: -3px;
}
#app-features-section .feature-card .plan-txt-xsm2{
  margin: 0;
}
/* fc (feature cards) border and padding  */
.fc-1{
  border: 1px solid #CCDCE3;
  border-top: 0;
  border-left: 0;
  padding: 0 2rem 2rem 0;
}
.fc-2{
  border: 1px solid #CCDCE3;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 2rem 2rem 2rem;
}
.fc-3{
  border: 1px solid #CCDCE3;
  border-top: 0;
  border-right: 0;
  padding: 0 0 2rem 2rem;
}
.fc-4{
  border: 1px solid #CCDCE3;
  border-top: 0;
  border-left: 0;
  padding: 2rem 2rem 2rem 0;
}
.fc-5{
  border: 1px solid #CCDCE3;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 2rem 2rem 2rem 2rem;
}
.fc-6{
  border: 1px solid #CCDCE3;
  border-top: 0;
  border-right: 0;
  padding: 2rem 0 2rem 2rem;
}
.fc-7{
  border: 1px solid #CCDCE3;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  padding: 2rem 2rem 0 0;
}
.fc-8{
  border: 1px solid #CCDCE3;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  padding: 2rem 2rem 0 2rem;
}
.fc-9{
  border: 1px solid #CCDCE3;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  padding: 2rem 0 0 2rem;
}



/* Curated Diet Plans */


.grass-bg1 {
  width: 45% !important;
}

.recipies-grp {
  margin-left: -177px;
  width: 45% !important;
}

.grass-bg2 {
  width: 45% !important;
  right: 0;
  top: 0;
  transform: rotatey(180deg);
}

.meal-grp {
  width: 96% !important;
}


/* Subscribe Email portion  */


.subs-left {
  width: 55%;

}

.subs-right {
  width: 45%;

}

.subs-email {
  padding: 16px 24px;
  gap: 10px;
  width: 371px;
  height: 59px;
  background: #F4F4F4;
  border-radius: 8px;
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #555555;
  opacity: 0.6;
  border: none;
}

.subs-button {
  width: 168px;
  height: 59px;
  background: linear-gradient(90deg, #152331 0%, #000000 100%);
  border-radius: 8px;
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;
}

.subscribe-form {
  display: flex;
  column-gap: 0.4rem;
}

.subscribe-heading {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 124%;
  color: #131E34;
}

.subscribe-paragraph {
  font-family: 'satoshi-medium';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: normal;
  color: #21345A;
}

/* Informatory Videos  */

.info-vid-main {
  display: flex;
  align-items: center;
}

.info-vid-left {
  width: 40%;
}

.info-vid-right {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.informative-video {
  width: calc(100% - 24.5px) !important;
  left: 4.7% !important;
}

.ques {
  justify-content: space-between;
  padding: 1rem 0rem;
  border-bottom: 1px solid #d2d2d2;
}

.border-removed {
  border-bottom: unset !important;
}

.ques span {
  font-size: 31px;
  font-family: monospace;
  display: none;
}

.ques-p {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: justify;
  color: #131E34;
}

/* .intro-video-2 {
  position: absolute !important;
  height: 96%;
  border-radius: 34px;
  object-fit: cover;
  left: 4% !important;
  top: 2%;
  width: calc(100% - 19.5px) !important;
} */

.intro-right-2 {
  position: unset !important;
  /* width: 21rem; */
  padding: 4rem;
}

.info-vid-link a {
  text-decoration: unset;
  color: #4F4D9D;
}

.recipe-left {
  row-gap: 4rem;
  padding-top: 3rem;
}

/* Curated Diet Plans */

.recipy-left {
  row-gap: 4rem;
}

.recipe-body {
  height: 53rem;
}


/* Workout Sliers Section */


.workout-body-wraper {
  background: #191A30;
  border-radius: 30px;
  width: 100%;
  padding: 3rem;
}


.workout2-left {
  background: #191A30;
  border-radius: 30px;
  padding: 3rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}


.workout3-right {
  background: #000000;
}

.challenge-dash {
  bottom: -9px;
}

/* Image positioning */


.trianers-iphone {
  top: -17%;
}

.john-shek {
  left: 1%;
  top: -1%;
}

.omar-saeed {
  left: 9%;
  bottom: -8%;
}

.aaron-ag {
  left: 9%;
  bottom: 25%;
}

.muscles-plan {
  right: -9%;
  top: 23%;
}

.home-lifting {
  right: 12%;
  bottom: 5%;
}


/* Stepper Section How it works */



/* Testomonial section */



.testo-slide {
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  position: relative;
  width: 100%;
  max-width: 440px;
  height: 390px;
  background: #F8F8FA !important;
  border: 1px solid #CCD0E1;
  border-radius: 24px !important;
  padding: 32px;
}

.testo-text {
font-family: 'satoshi-medium';
font-style: normal;
font-weight: 500;
font-size: 18px;
color: #3A4555;
margin: 0 !important;
}
.testo-text strong{
  font-weight: 600 !important;
  color: #3A4555 !important;
}

.testo-aurthor {
  font-family: 'cabinetGrotesk-xbold';
font-style: normal;
font-weight: 800;
font-size: 20px;
color: #1B2B4B;
margin: 0 0 1rem 0 !important;
}
.testo-footer{
  margin-top: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.testo-footer .store-desc{
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: start;
}
.testo-footer .store-desc .review-stars{
  display: flex;
  align-items: center;
  gap: 4px;
}
.testo-footer .store-desc .store-name{
font-family: 'satoshi';
font-style: normal;
font-weight: 500;
font-size: 12px;
color: #1B2B4B;
margin: 0;
}

.testimonial .owl-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: 1rem;
}






/* Macro Mate Section, workout logging section */

/* Mate Card 1 */
.mac-row1 > div{
  height: 41rem;
}
.mate-body{
  width: 549px;
  height: 435px;
}
.macro-food1 {
  position: absolute;
  margin-left: -72px;
  width: 80%;
  top: 0;
}
/* Mate Card 2 */
.mate-body2{
  width: 262px;
}
.mate-body2 img{
  width: 100%;
}
/* Mate Card 3 */
.mate-body3{
  height: unset;
  width: 353px;
}

/* Mate Card 4 */
.mate-body4{
  width: 233px;
}
.log-food {
  top: 53px;
  left: 174px;
  width: 52%;
}
.log-food1{
  width: 13rem;
  bottom: 0;
  z-index: 9;
  position: absolute;
  left: 4rem;
}
.log-food2{
  width: 13rem;
  margin-bottom: 5rem;
  float: right;
}
/* card-5  */
 .mate-body5 {
  width: 350px;
}
.mac-row2 > div{
  height: 49rem;
}
.mac-row2 > div > div{
  height: 100%;
}
 /* wokout loging  */
 #workout-loging-mate-section .mac-row1 > div{
  height: 41rem;
}
#workout-loging-mate-section .mate-body{
  width: 548px;
  height: 415px;
}
#workout-loging-mate-section .macro-food1 {
  position: absolute;
  margin-left: -72px;
  width: 80%;
  top: 0;
}
#workout-loging-mate-section .log-food {
  top: 4px;
  left: 174px;
  width: 52%;
}
 /* weight managment  */
 .track-weight{
  margin-right: -1.5rem;
 }

/* Step counter Section */



.step-body2 {
  align-self: flex-start;
  width: 100%;
}

.step-activities {
  margin-left: auto;
  filter: drop-shadow(-152px 10px 61px rgba(0, 0, 0, 0.01)) drop-shadow(-85px 6px 51px rgba(0, 0, 0, 0.05)) drop-shadow(-38px 2px 38px rgba(0, 0, 0, 0.09)) drop-shadow(-9px 1px 21px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
  border-radius: 20px;
}



/* blogs Page  */

.recent-articles {
  width: 50%;
}

.trending-articles {
  width: 50%;
}

.all-articles-card {
  width: 32%;
  padding: 0px 0px 20px 0px;
  box-shadow: 0px 38px 39px rgba(181, 181, 181, 0.01), 2px 0px 37px rgba(181, 181, 181, 0.25), 0px 28px 28px rgba(181, 181, 181, 0.09), 0px 7px 16px rgba(181, 181, 181, 0.1), 0px 0px 0px rgba(181, 181, 181, 0.1);
  border-radius: 10px;
}

.bottom-cards-wraper .blogs-arrow {
  width: 14px !important;
  height: 14px !important;
}

.blogs-title {
  width: 50%;
}

.recent-article-card {
  box-shadow: 0px 113px 45px rgba(181, 181, 181, 0.01), 0px 64px 38px rgba(181, 181, 181, 0.05), 0px 28px 28px rgba(181, 181, 181, 0.09), 0px 7px 16px rgba(181, 181, 181, 0.1), 0px 0px 0px rgba(181, 181, 181, 0.1);
  border-radius: 10px;
  background-color: #fff;
  height: 100%;
}

.inner-sm-heading:hover {
  color: #0d6efd;
  text-decoration: underline;
}

.big-card-head:hover {
  color: #0d6efd;
  text-decoration: underline;
}

.blogs-side-card {
  padding: 5px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 30px; */
  box-shadow: 0px 113px 45px rgba(181, 181, 181, 0.01), 0px 64px 38px rgba(181, 181, 181, 0.05), 0px 28px 28px rgba(181, 181, 181, 0.09), 0px 7px 16px rgba(181, 181, 181, 0.1), 0px 0px 0px rgba(181, 181, 181, 0.1);
  border-radius: 10px;
  background-color: #fff;
}

.blogs-side-img {
  display: flex;
  align-items: center;
}

.blogs-nav .nav-item .active {
  color: black !important;
}

.blogs-nav .nav-item .active::after {
  height: 2px;
  background: black;
}

.blog-side-text {
  justify-content: center;
}

.blogs-side-img {
  width: 50%;
  flex-shrink: 0;
}

.sm-card-wraper {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

img.random-card-img {
  height: 15rem;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.big-card-wraper .big-card-date {
  font-size: 19px;

}

.big-card-heading {
  padding: 0px 10px;
}

.big-card-date {
  padding: 0px 10px;
}

.inner-sm-description {
  padding: 0px 10px;
}

.big-card-bottom {
  padding: 0px 10px;
}

/* About Us page */



.about-sec1-button {
  border: 2px solid #4F30CC;
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #4F30CC;
  padding: 9px 24px;
}

.about-sec1-button:hover {
  background-color: #4F30CC;
  color: white;

}

.diet-plan-about {
  top: -80px;
  left: 13px;
}

.focused-about {
  top: -80px;
  left: 13px;
}

.info-videos-about {
  top: -80px;
  left: 13px;
}

.step-counter-about {
  top: -80px;
  left: 13px;
}

.about-sec2-card {
  background-color: #F7F7F7;
  padding: 3.5rem 2rem 1rem;
  width: 49%;

}

.about-body1-wraper {
  row-gap: 5rem;
  column-gap: 1rem;
  justify-content: space-between;

}

.about-body2-wraper {
  row-gap: 5rem;
  column-gap: 1rem;
  justify-content: space-between;
}

/* Diet plan Crousel section */
img.healthy-grp {
  width: 407px !important;
}

/* Blogs Section new design */
.blogs-nav .nav-item .active {
  color: black !important;
  background-color: unset !important;
  font-family: 'satoshi-medium';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  color: #2C2C2C !important;
  opacity: unset !important;

}

.blogs-nav .nav-item .nav-link {
  font-family: 'satoshi-medium';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #2C2C2C;
  opacity: 0.6;
}

.blogs-nav .nav-item .active::after {
  height: 1px;
  background: black;
  z-index: 33;

}

.nav-vertical-line {
  height: 1px;
  background: #988b8b;
  position: absolute;
  top: 39px;
}

.blogs-nav .navbar-expand-lg .navbar-nav {
  column-gap: 4rem;
}

.blogs-arrow {
  width: 26px;
  height: 26px;
  right: 8px;
  top: 4px;
}

.social-wraper {
  column-gap: 1rem;
}

.blogs-share-sm {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 32px;
  color: #21345A;
  white-space: nowrap;
  margin: 0;
}

.sm-card-wraper .social-wraper {
  column-gap: 1rem;
}


.sm-card-wraper .big-card-bottom {
  column-gap: 0.5rem;
}

.sm-card-wraper {
  height: 692px;
  overflow: scroll;
  position: relative;

}

.shadow-top {
  display: block;
  /* min-width: 633px; */
  width: 100%;
  z-index: 1;
  min-height: 150px;
  background: linear-gradient(#fff, transparent);
  top: -16px !important;
}

.shadow-bottom {
  display: block;
  width: 100%;
  /* min-width: 633px; */
  min-height: 150px;
  background: linear-gradient(transparent, #fff);
}

.shadow-up {
  top: 0;
  right: -8px;
  pointer-events: none;
  width: 100%;
  display: none;
}

.shadow-down {
  bottom: 0;
  width: 100%;
  z-index: 1;
  right: -8px;
  pointer-events: none;
  display: none;
}

.blog-content a {
  text-decoration: none;
  color: var(--bs-link-color) !important;
}

.accordion-item {
  border: 0px solid !important;
}

.accordion-item .accordion-button {
  border-bottom: 1px solid #d2d2d2;
  border-left: 0px solid !important;
  border-right: 0px solid !important;
}

.accordion-button:focus {
  border-color: unset !important;
  outline: 0 !important;
  box-shadow: unset !important;
}

.accordion-button:not(.collapsed) {
  background: none;
}

.accordion-item {
  background: #F4F4F4;
  border-radius: 20px;
}

button.accordion-button {
  border-color: #d2d2d2 !important;
}

button.accordion-button:not(.collapsed) {
  border: none !important;
  outline: 0 !important;
}

.faq-head {
  font-family: 'satoshi-medium';
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 120%;
  text-align: left;
  color: #131E34;
  opacity: 0.8;
  text-transform: capitalize;
  column-gap: 10px;
}

.faq-ans {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: justify;
  color: #21345A;
}

.blogs-card-arrow {
  width: 13px;
  height: 13px;
}

.blog-card-readm p {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 2px;
  color: #21345A;
  margin: 0;
}

/* Page 2 Blogs
                category: Workouts */

.blogs-page {
  background-color: white;
}

/* .nav-item .active::after {
                    content: "";
                    position: absolute;
                    bottom: 8%;
                    left: 10%;
                    background: #6F73BC;
                    border-radius: 20px;
                    height: 3px;
                    width: 60%;
                } */

.social-links a {
  text-decoration: none;
}

span.line {
  height: 0.1px;
  width: 100%;
  background-color: #CECECE;
}

.card-title p {
  white-space: nowrap;
}

.card-title {
  column-gap: 0.3rem;
}

.sub-nav-container {
  height: 60px;
  padding: 0rem 5rem;
  column-gap: 1rem;
}

.subnav-logo {
  width: 23%;
}

p.subnav-title {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: white;
  margin: 0;
}

.subnav-menu a {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: white;
  margin: 0;
}

.cg {
  column-gap: 1rem;
}

.rg {
  row-gap: 1rem;
}

.blogs-logo {
  border-right: 1px solid #4E5074;
  cursor: pointer;
}

.subnav-menu {
  column-gap: 5rem;
}

.subnav-menu img {
  width: 28px;
}

.subnav-menu li {
  display: flex;
  opacity: 0.2;
}

.subnav-menu li.active {
  opacity: 1;
}

.subnav-menu li:hover {
  opacity: 1;
}

.subnav-menu li a:hover {
  color: #fff;
}

.round-border {
  border-radius: 200px !important;
  background-color: none;
}

.right-arrrow-pgn,
.left-arrrow-pgn {
  width: 9px;
  height: 28px;
}

.pgn-icon-height {
  height: 37px;
  width: 37px;
}

.pagination-icons {
  border: 1px solid #37395D;
}

.page-num:focus,
.pagination-item.selected .page-num {
  z-index: 3;
  color: #fffdfd !important;
  background-color: #37395D;
  /* outline: 0; */
  /* box-shadow: var(--bs-pagination-focus-box-shadow); */
  box-shadow: none !important;
}

.page-num {
  border: none;
  border-radius: 200px;
  background-color: transparent;
  color: black !important;
}

.page-num.active_link {
  background-color: #37395D;
  color: #fffdfd;
}

/* Carasoul Custom styling */
.cards-carasol .owl-dots {
  display: none;
}

.cards-carasol .item .card:hover {
  transform: translate(0%, -3%);
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.cards-carasol button:hover {
  z-index: 3;
  /* color: var(--bs-pagination-focus-color); */
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: none;
}

.cards-carasol .card-body {
  height: 5.5rem;
}

.cards-carasol div:nth-child(2) {
  display: flex;
  justify-content: center;
  column-gap: 2rem;
  height: 5.5rem;
}

.cards-carasol .owl-item {
  padding: 0 !important;
}

.cards-carasol .item {
  padding: 0 !important;
  border-radius: 19px 19px 0px 0px;
  box-shadow: none !important;
}

.cards-carasol .item .card {
  width: auto !important;
  border-radius: 19px 19px 13px 13px;
  border: none;
  box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.1);
}

.cards-carasol .card-img-top {
  border-radius: 19px 19px 0px 0px;
}

.blogs-page>.container {
  padding-top: 6rem;
}

.cards-carasol .owl-stage {
  display: flex;
  padding: 1rem 0rem 2rem 0rem;
}

.cards-carasol .card-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #373D3F;
}

p.cd-title {
  left: 80px;
  top: 250px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.08em;
  color: #6F73BC;
  font-size: clamp(1rem, 1.5vw + 0.5rem, 1.75rem)
}

p.cd-date {
  left: 726px;
  top: 1378px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #373D3F;
  font-size: clamp(0.4rem, 1vw + 0.3rem, 1rem)
}

.blogs-container .card {
  border: none
}

.blogs-container .card-heading {
  font-family: satoshi-medium;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 100%;
  color: #2C2C2C;
}

.blogs-container .card-sub-heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #373D3F;
  flex: none;
  flex-grow: 0;
  font-size: clamp(0.7rem, 1vw + 0.5rem, 1rem);
}

.blogs-container .card-mid-p {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #2C2C2C;
}

.blogs-list {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #2C2C2C;
  line-height: 26px;
  align-items: center;
  flex: none;
  row-gap: 0 !important;
  flex-grow: 0;
  line-height: 30px;
  margin: 0;
}

.blogs-text-wraper li {
  font-family: 'satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #2C2C2C;
  line-height: 26px;
  align-items: center;
  flex: none;
  row-gap: 0 !important;
  flex-grow: 0;
  line-height: 30px;
  margin: 0;
}

.blogs-h3 {
  margin: 0;
  padding: 0;
  font-family: 'satoshi-medium';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 49px;
  color: #2C2C2C;
}

.blogs-container .r-side {
  row-gap: 1rem;
}

.blogs-container .blogs-h2 {
  margin: 0;
  padding: 0;
  font-family: 'satoshi-medium';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 49px;
  color: #2C2C2C;
}

span.share-to {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #373D3F;
  font-size: clamp(0.7rem, 1vw + 0.5rem, 1rem)
}

.r-side .card-bottom {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.card-bottom .social-img-fluid {
  width: 32px;
  height: 26px;
}

.card-bottom .social-links {
  display: flex;
  justify-content: space-between;
}

/* Blog content Page */
/* when user clicks on read more */
.blogs-container .social-img-fluid {
  width: 20px;
  height: 16px;
}

.card-bottom a {
  text-decoration: none;
}

.cards-carasol button:hover {
  background-color: unset !important;
  color: unset !important;
}

.share-to {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #373D3F;
  font-size: clamp(0.7rem, 1vw + 0.5rem, 1rem)
}


/* section */

.psuedo-hero {
  width: 100%;
  height: calc(92% - 68px);
  object-fit: cover;
  border-bottom-right-radius: 45px;
}

.absolute-bottom {
  position: absolute;
  bottom: 16%;
  padding: 1rem 3rem 3rem 2rem;
  width: 100%;
}

.set-imgs {
  position: absolute;
  top: -11%;
  right: -11%;
  width: 70%;
}

.sub-heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  color: #6F73BC;
}

.para {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: #373D3F;
}

.center-download-btn {
  background: linear-gradient(270.19deg, #6F73BC 0.17%, #3E3C93 99.86%);
  border-radius: 50px;
  display: block;
  margin-top: 7rem;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 36px;
  color: #FFFFFF;
  border: 0;
  /* width: 20%; */
}

.center-download-btn:hover {
  color: #FFFFFF;
}

.appLink {
  text-decoration: none;
}

.square-loc {
  position: absolute;
  left: 0;
  bottom: 0;
}

/* .background-hero {
    background-image: url("../public/assets/hero-section.png");
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top right;
} */

.list-styling {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125;
  line-height: 24px;
  color: #FFFFFF;
}

.column::before {
  content: "";
  position: absolute;
  left: 10%;
  top: 0;
  height: 100%;
  width: 80px;
  background-color: #6F73BC;
}

.hero-img-right {
  width: 100%;
  height: 87%;
  object-fit: cover;
  border-bottom-left-radius: 100px;
}

.small-column::after {
  content: "";
  position: absolute;
  display: inline-block;
  right: 0;
  width: 80px;
  height: 45%;
  background-color: #3E3C93;
  transform: translate(0%, 60%);
}

.footer-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 34px;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
}

.app-options-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-bottom: 0.85rem;
}

.btn .app-media {
  width: auto;
  height: 52px;
}

.social-img-fluid {
  width: 45px;
  height: 45px;
}

.anchor {
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
}

.anchor:hover {
  color: #FFFFFF;
}

.purple-bg {
  position: relative;
  background-color: #6F73BC;
}

.padding-fluid {
  padding: 2rem 4% 3rem 20%;
}

.workout-gif {
  position: absolute;
  height: 85%;
  width: auto;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iphone-stand {
  /* height: 85vh; */
  height: 85%;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.carousel-sub-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 24px;
  text-align: center;
  color: #757575;
}

.carousel-heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.625rem;
  line-height: 63px;
  text-align: center;
  color: #373D3F;
}

.skewed::after {
  content: "";
  position: absolute;
  right: -15%;
  top: 0;
  width: 250px;
  height: 100%;
  background-color: #37395d;
  z-index: -1;
  border-radius: 25px;
  transform: skewX(-17deg);
}

.square-invert {
  position: absolute;
  left: 0;
  top: 0;
  width: 125px;
  height: 125px;
}

.img-last-section {
  width: auto;
  height: auto;
  margin-bottom: -12px;
}

.last-section-wrapper {
  position: relative;
  background-color: #191A30;
  overflow: hidden;
  padding-top: 5rem;
}

.owl-carousel .owl-stage-outer {}

.item {
  background: #FFFFFF;
  /* box-shadow: 0px 4px 24px rgb(0 0 0 / 25%); */
  border-radius: 10px;
  /* padding: 1rem; */
  /* height: 100%; */
}

.owl-item.active {
  padding-left: 1rem;
  padding-right: 1rem;
}

.three-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.video-fluid {
  position: absolute;
  height: 96%;
  border-radius: 34px;
  object-fit: cover;
  left: 5.5%;
  top: 2%;
  /* width: 88%; */
  width: calc(100% - 36.5px);
}

.semi-rounded {
  background: #37395D;
  /* border-radius: 400px 0px 0px 400px; */
  border-bottom-left-radius: 400px;
  border-top-left-radius: 400px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  padding: 12% 2rem 12% 22%;
}

.meal-plan-gif {
  height: 50%;
  width: 65%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.food-diary-gif {
  height: 50%;
  width: 65%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.comma-top {
  position: absolute;
  left: -8%;
  top: -13%;
  z-index: 99;
}

.comma-bottom {
  position: absolute;
  right: -8%;
  bottom: -13%;
  z-index: 99;
}

.container-padding-fluid {
  padding-top: 2rem;
  padding-bottom: 7rem;
}

.negative-margin {
  margin-top: -7%;
}
/* Buf fixing  */
/* pegination next prev btn issue  */
.page-link{
  padding: 0 !important;
}

@media screen and (min-width: 1441px) {
  .mn-height-mate-card {
    /* min-height: 30rem; */
    /* min-height: 48rem */
  }
  .faq-head {
    font-size: 18px !important;
  }

  .hero-buttons-applink img {
    width: 100%;
    max-width: 177px;
    min-width: 120px;
  }
  .plan-txt-sm-black{
    font-size: 18px;
  }
}
@media (min-width:1240px){
 .container-xxl{
  max-width: 1220px;
 }
}
@media (min-width:1400px){
 .container-xxl{
  max-width: 1320px;
 }
}
@media screen and (max-width: 1440px) {
  html {
    font-size: 14px;
  }

  .intro-right {
    padding: 2rem;
  }

  .intro-left img {
    width: 95%;
  }

  .mn-height-mate-card {
    /* min-height: 30rem; */
  }

  .hero-buttons-applink img {
    max-width: 170px;
  }
  .hero-heading{
    font-size: 50px;
  }

}

@media screen and (max-width: 1200px) {
  /* Homepage introduction section responsiveness */

  .intro-wraper {
    flex-direction: column !important;
  }

  .intro-right {
    position: unset;
  }

  .intro-left img {
    width: 100%;
  }

  .intro-mid {
    row-gap: 2rem;
  }

  .intro-right-2 {
    position: unset !important;
    width: 21rem;
    padding: 0rem;
  }

  .py-3rem-12 {
    padding: 3rem 0rem !important;
  }


  .step-body2 img {
    width: 50%;
    height: fit-content;
  }

  .stepper-iphone {
    width: 100%;
    height: fit-content;
  }

  /* .hero-buttons-applink img {
    width: auto;
  } */

  .card-inner-title {
    font-size: 28px;
  }

  /* Blog page Responsiveness */

  .recent-articles {
    width: 100%;
  }

  .trending-articles {
    width: 100%;
  }

  .blogs-page-top {
    flex-direction: column;
  }

  .trending-articles .inner-sm-heading {
    font-family: 'satoshi-medium';
    font-size: 25px;
    font-weight: 700;
  }

  .trending-articles .inner-sm-description {
    font-family: 'satoshi';
    font-size: 20px;
    color: #555555;
  }

  .blog-side-text {
    row-gap: 1rem;
    width: 50%;
  }

  .blogs-side-img {
    width: 50%;
  }

  .hero-sec-homepg .text-logo img {
    width: 9rem !important;
  }

  .blogs-container .card-heading {
    font-size: 32px;
  }

  .big-card-head {
    font-size: 25px;
  }

  /* .mn-height-mate-card {
    min-height: 30rem;
  } */
  /* mate cards  */
  .mate-body{
    width: 545px;
    height: 434px;
  }
  .mate-body2{
    width: 238px;
  }
  .mate-body3{
    width: 206px;
  }
  .mate-body4{
    width: 124px;
  }
  .mate-body5{
    width: 189px;
  }
  .mac-row2 > div{
    height: 33rem;
  }
  /* testimonial slides  */
  .testo-slide {
    max-width: 100%;
  }


}
@media screen and (min-width:990px) and (max-width: 1200px){
  .hero-heading{
    font-size: 36px;
  }
  .plan-txt-md{
    font-size: 16px;
  }
  .hero-buttons-applink img{
    max-width: 170px;
  }
  .download-stats > div h4{
    font-size: 18px;
  }
  .download-stats > img{
    width: 150px;
  }
  .hero-left-lower span{
    font-size: 26px;
  }
  .hero-left-lower p{
    font-size: 10px;
  }
  #app-features-section{
    padding: 16px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {

  html {
    font-size: 15px;
  }

  .nav-logo {
    display: none;
  }

  .nav-logo-sm {
    display: block;
    height: 50px;
    width: auto;
  }

  .nav-item .active::after {
    bottom: 13%;
    left: 0%;
    background: #6F73BC;
    border-radius: 20px;
    height: 3px;
    width: 75px;
  }

  .AlignSelfCenter {
    align-self: center;
  }

  .iphone-stand {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .padding-0 {
    padding: 0px;
  }

  .skewed::after {
    display: none;
  }

  .last-section-wrapper {
    padding-top: 0rem;
  }

  .app-btns-sm .absolute-bottom {
    bottom: 14%;
  }

  .comma-top {
    display: none;
  }

  .comma-bottom {
    display: none;
  }

  .container-padding-fluid {
    padding-top: 2rem;
    padding-bottom: 9rem;
  }



}



/* mobile */
@media screen and (max-width: 767px) {
  html {
    font-size: 16px;
  }

  /* .nav-logo {
    display: none;
  } */

  .nav-logo-sm {
    display: block;
    height: 50px;
    width: auto;
  }

  .nav-item .active::after {
    content: "";
    position: absolute;
    bottom: 13%;
    left: 0%;
    background: #6F73BC;
    border-radius: 20px;
    height: 3px;
    width: 75px;
  }

  .app-btn {
    text-align: center;
  }

  .absolute-bottom {
    bottom: 24%;
    width: 95%;
  }

  .app-btns-sm .absolute-bottom {
    bottom: 14%;
  }

  .second-hero-section {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .second-hero-section img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .purple-bg {
    background-color: transparent;
  }

  .center-download-btn {
    margin-top: 3rem;
  }

  .square-dots::after {
    display: none;
  }

  .padding-fluid {
    padding: 1rem 4% 1rem 4%;
  }

  .custom-diet-bg {
    background-image: none;
    background-color: #37395D;
  }

  .small-column::after {
    display: none;
  }

  .column::before {
    display: none;
  }

  .padding-0 {
    padding: 0px;
  }

  .workout-gif {
    position: absolute;
    height: 95%;
    width: auto;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0.5rem;
  }

  .skewed::after {
    display: none;
  }

  .img-last-section {
    width: 100%;
    height: auto;
    margin-bottom: -12px;
  }

  .last-section-wrapper {
    margin-top: 2rem;
    padding-top: 2rem;
  }

  .iphone-stand {
    height: 65%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .flex-column-reverse-sm {
    flex-direction: column-reverse;
  }

  .sub-heading {
    font-size: 2rem;
  }

  .semi-rounded {
    border-bottom-right-radius: 400px;
    border-top-right-radius: 400px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    padding: 22% 3rem 22% 5%;
  }

  .para {
    text-align: justify;
  }

  .meal-plan-gif {
    height: 65%;
    width: 55%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .food-diary-gif {
    height: 65%;
    width: 55%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .h100 {
    height: 100%;
  }

  .comma-top {
    display: none;
  }

  .comma-bottom {
    display: none;
  }

  .container-padding-fluid {
    padding-top: 2rem;
    padding-bottom: 6rem;
  }

  .secondary-wrapper {
    background-color: #F9FBFF;
    border-bottom-left-radius: 160px;
    border-top-right-radius: 160px
  }

  /* blog page responisveness for tablets and phones */

  @media screen and (max-width: 500px) {
    .psuedo-hero {
      height: calc(80vh - 60px);
    }

    .app-btns-sm .absolute-bottom {
      bottom: -5%;
    }

    .absolute-bottom {
      bottom: 23%;
      width: 95%;
    }

    .second-hero-section {
      margin-top: 3rem;
      align-items: center;
    }

    .negative-margin {
      margin-top: 1%;
    }
  }
}


/* Blogs page tab view */

@media screen and (max-width: 991px) {

  /* .h89vh{
    height: auto;
  } */
  .menu-wraper {
    box-shadow: none !important;
    border-radius: unset !important;
  }

  li.nav-item {
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .download-buttonH {
    width: max-content;
    align-self: center;
    border: none;
    margin-top: 1rem !important;
  }

  .nav-item .active::after {
    display: none;
  }

  .blogs-container .card-inner {
    flex-direction: column;
    row-gap: 2rem;
  }

  .blogs-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .blogs-container .card-mid-p {
    line-height: 26px;
  }

  .cards-carasol .card-text {
    font-size: 12px;
  }

  .sub-nav .nav-item img {
    width: 20px;
  }

  .sub-nav .nav-link {
    font-size: 16px;
  }

  .subnav-logo {
    width: 18%;
  }

  p.subnav-title {
    font-size: 22px;
  }

  .sub-nav-container {
    column-gap: unset;
  }

  .subnav-menu {
    column-gap: 2rem;
  }



  /* Home Page Responsiveness */

  .hero-wraper {
    flex-direction: column !important;
  }

  .hero-left {
    row-gap: 1.5rem;
    width: 100%;
  }

  .subscribe-paragraph {
    font-size: 19px;
  }

  .subscribe-heading {
    font-size: 35px;
  }

  .subs-email {
    width: auto;
    height: auto;
  }

  .subs-email {
    width: auto;
    height: auto;
  }

  .subs-right img {
    width: 100%;
  }

  .intro-sub-heading {
    font-size: 42px;
  }

  .intro-heading {
    font-size: 52px;
  }

  .plan-txt-sm-white {
    font-size: 19px;
  }

    /* app features section  */

  /* App features Section */


.features-card-wraper {
  width: 100%;
  background-color: white;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}

.features-logo1 {
  width: 100%;
}

.feature-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background: #FFFFFF;
  width: 50%;
  min-width: 285px;
  padding: 2rem;
}


.feature-card-logo{
box-sizing: border-box;
width: 54px;
height: 54px;
border: 1px solid #D7DCE3;
border-radius: 48px;
display: flex;
align-items: center;
justify-content: center;
}
.feature-card-logo img {
 width: 28px;
 height: 28px;
}
#app-features-section .feature-card .app-titleh{
  font-size: 18px;
  font-family: 'cabinetGrotesk-bold';
  margin: 0;
  margin-bottom: -2px;
}
#app-features-section .feature-card .plan-txt-xsm2{
  margin: 0;
}
.fc-1, .fc-3, .fc-5, .fc-7, .fc-9 {
  border-right: 1px solid #CCDCE3 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}
.fc-2, .fc-4, .fc-6, .fc-8{
  border: 0 !important;
}


  .ques-p,
  .ques span {
    font-size: 27px;
  }

  .info-vid-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
  }

  .info-vid-left {
    width: 100%;
  }

  .info-vid-right {
    width: 100%;
  }

  .hero-right video {
    width: 50%;
  }

  .hero-right {
    justify-content: center;
  }

  .hero-sec-homepg .text-logo img {
    width: 9rem;
  }


  /* Section Macro mate responsiveness */

  .mate-content-wraper {
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
  }
  .step-content-wraper {
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
  }
  .mn-height-mate-card {
    min-height: auto;
  }
  .mate-body{
    width: 545px;
    height: 434px;
  }
  .mate-body2{
    /* width: 320px; */
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mate-body2 img{
    width: 100%;
    max-width: 300px;
  }
  .mate-body3{
    /* width: 320px; */
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mate-body3 img{
    width: 100%;
    max-width: 300px;
  }
  .mate-body4{
    /* width: 260px; */
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mate-body4 img{
    width: 100%;
    max-width: 300px;
  }
  .mate-body5{
    /* width: 320px; */
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mate-body5 img{
    width: 100%;
    max-width: 300px;
  }
  .macro-food2 {
    max-width: 500px;
}
.track-weight{
  max-width: 500px;
}
  .mac-row2 > div{
    height: auto;
    width: 100%;
  }
  .mac-row1 > div{
    height: auto;
    width: 100%;
  }

  /* Stepper Section */


  /* new blogs page */
  .all-articles-card {
    width: 48%;
  }

  .all-articles-card .social-wraper {
    column-gap: 1rem;
  }

  .all-articles-card .big-card-bottom {
    flex-direction: column;
  }

  .bottom-cards-wraper {
    row-gap: 2rem;
  }

  .trending-articles .inner-sm-description {
    font-size: 16px;
  }

  .trending-articles .inner-sm-heading {
    font-size: 20px;
  }

  .blog-side-text {
    justify-content: center;
    row-gap: 0rem;
  }

  /* About us Page */

  .about-body1-wraper {
    flex-direction: column;
  }

  .about-left {
    order: 2;
  }

  .about-sec2-card {
    width: 48%;
  }

  /* Diet plane Crousel */
  .recipe-body {
    flex-direction: column !important;
    row-gap: 1rem;
  }

  .recipy-right {
    order: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .recipy-left {
    order: 2;
  }

  .healthy-grp {
    order: 3;
  }

  .meal-grp {
    width: 45% !important;
  }

  .heading-64px {
    font-size: 38px;
  }

  .recipe-body p,
  .diet-body p {
    font-size: 18px;
  }

  .recipe-body {
    height: auto;
  }

  /* Work out Goals Section Responsiveness */
  .workout-slide2,
  .workout-slide1 {
    background-color: #191A30 !important;
    min-height: unset;
  }

  .workout3-body-wraper {
    min-height: unset;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .workout-body-wraper {
    flex-direction: column;
    row-gap: 5rem;
    align-items: center;
    justify-content: center;
  }

  .workout2-body-wraper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .exercise-grp {
    width: 78% !important;
  }



  .workout3-right {
    display: flex;
    justify-content: center;
  }

  .heading-48px-white {
    font-size: 40px;
  }

  .home-lifting {
    right: 12%;
    bottom: 5%;
  }

  .text-logo img {
    width: 16rem !important;
  }

  .home-lifting {
    width: 100% !important;
  }

  .aaron-ag {
    width: 100% !important;
  }

  .blogs-container .card-heading {
    font-size: 29px;
  }
  .hero-right{
    width: 100%;
  }
  .hero-right img{
    width: 100%;
    max-width: 700px;
  }
}

/* blog page responisveness for tablets and phones */
@media screen and (max-width: 767px) {
  .sub-nav-container {
    padding: 0rem 2rem;
  }

  .sub-nav .nav-item img {
    width: 17px;
  }

  .sub-nav .nav-link {
    font-size: 14px;
  }

  .subnav-menu {
    column-gap: 1.5rem;
    padding-left: 1rem !important;
  }

  /* Hero section responsiveness */

  .app-titleh {
    font-weight: 500;
    font-size: 17px;
    line-height:normal;
  }

  .hero-heading {
    font-size: 48px;
    line-height: normal;
    margin-bottom: 0;
  }
  .hero-right img{
    width: 100%;
  }

  .hero-sub-heading {
    font-size: 44px;
    line-height: 39px;
  }

  .hero-buttons-applink {
    justify-content: start;
  }

  .plan-txt-md {
    font-size: 20px;

  }

  .button-lower-sm-txt {
    font-size: 20px;
    line-height: 20px;
  }

  .button-upper-sm-txt {
    font-size: 9px;
    line-height: 15px;
  }

  .hero-left-lower span {
    font-size: 26px;
    line-height: normal;
  }

  .hero-left-lower p {
    font-size: 14px;
    line-height: normal;
  }

  .hero-buttons-applink a.app-btn {
    width: 257px;
    height: 66px;
  }

  /* subscribe section */
  .subscription {
    flex-direction: column;
    row-gap: 4rem;
  }

  .subs-right {
    width: 100%;
  }

  .subs-left {
    width: 100%;
  }

  .subs-right img {
    width: 70%;
  }

  .intro-sub-heading {
    font-size: 32px;
  }

  .intro-heading {
    font-size: 42px;
  }

  .plan-txt-sm-white {
    font-size: 17px;
  }

  .app-working-title,
  .hero-sub-heading,
  .app-working-description,
  .app-features-description {
    width: 100% !important;
  }

  .testo-slide {
    padding: 1.5rem !important;

  }

  .testo-text {
    font-size: 18px;
    letter-spacing: 0;
    font-weight: 500;
  }

  .info-videos {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .info-vid-left {
    display: flex;
    justify-content: center;
  }

  .info-vid {
    flex-direction: column;
    align-items: center;
  }

  .info-vid-title {
    width: 100% !important;
  }

  .info-vid-title h1 {
    text-align: center;
  }

  .info-vid-link {
    width: 100% !important;
    order: 2;
    justify-content: center !important;
  }

  .subscribe-paragraph {
    /* text-align: center; */
  }

  .ques-p,
  .ques span {
    font-size: 22px;
  }

  .step-body2 {
    position: relative;
  }

  .step-history {
    width: 100% !important;

  }

  .step-right {
    height: 62rem;
  }

  .step-activities {
    position: absolute;
    left: 7%;
    bottom: -160px;
    width: 60% !important;
  }

  .blogs-side-img {
    width: 100%;
  }

  .blog-side-text {
    width: 100%;
  }

  .trending-articles .inner-sm-heading {
    font-size: 16px;
  }

  .blogs-side-card {
    flex-direction: column;
  }

  .blogs-title {
    width: 100%;
  }

  .blogs-description {
    width: 100%;
  }

  .card-inner-heading {
    font-size: 30px;
  }

  .subscribe-heading {
    font-size: 28px;
    text-align: center;
  }

  .exercise-grp {
    width: 100% !important;
  }

  .mate-body2 img {
    width: 100%;
  }

  .s2-body-wraper {
    min-height: auto !important;
  }

  .blogs-container .card-heading {
    font-size: 26px;
  }

  /* blogs-section  */
  .blogs-side-card {
    width: 50%;
  }

  .sm-card-wraper {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .blogs-container .blogs-h2 {
    font-size: 27px;
  }

  .blogs-h3 {
    font-size: 23px;
  }

  .sub-heading-wraper {
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  .single-blog-cards-bottom .all-articles-card {
    min-width: 25rem;
  }

  .single-blog-cards-bottom .blog-end-cards {
    overflow-x: scroll;
    padding-bottom: 2rem;

  }

  .faq-head {
    font-size: 18px !important;
  }

  .big-card-head {
    font-size: 23px;
  }

}

/* blog page responisveness for very small phones */
@media screen and (max-width: 610px) {
  .sub-nav-container {
    min-height: 60px;
    height: auto;
    padding: 0.5rem 0.5rem;
  }
  .download-stats{
    margin-top: 0 !important;
  }
  .hero-buttons-applink{
    padding-top: 0 !important;
  }
  .subnav-menu {
    align-items: center;
    padding-left: 0 !important;
    column-gap: unset;
  }

  .subnav-menu li {
    flex-direction: column;
    align-items: center;
  }
  .hero-buttons-applink img{
    width: 100%;
    max-width: 150px;
  }


  /* Hero section responsiveness */

  .app-titleh {
    font-weight: 500;
    font-size: 16px;
    line-height: normal;
  }

  .hero-heading {
    font-size: 30px;
    line-height: normal;
  }
  .hero-left {
    padding-right: 0rem;
    row-gap: 1rem;
    padding-left: 10px !important;
  }

  .hero-left-lower {}

  .xborder-card {
    padding: 0rem 1rem;
  }

  /* .hero-sub-heading {
    font-size: 32px !important;
    line-height: 28px;
  } */

  .plan-txt-md {
    font-size: 16px;
    line-height: 22px;

  }
  .hero-right{
    width: 100% ;
  }
  .hero-right img{
  }

  .button-lower-sm-txt {
    font-size: 20px;
    line-height: 20px;
  }

  .button-upper-sm-txt {
    font-size: 8px;
    line-height: 15px;
  }

  .hero-left-lower span {
    font-size: 22px;
    line-height: normal;
  }

  .hero-left-lower p {
    font-size: 13px;
    line-height: normal;
  }

  .hero-buttons-applink a.app-btn {
    width: 200px;
    height: 57px;
  }

  .container {
    padding: 1rem !important;
  }

  .text-logo img {
    width: 124px;
  }

  .intro-mid {
    padding: 1.5rem 2rem;
  }

  .intro-sub-heading {
    font-size: 22px;
  }

  .intro-heading {
    font-size: 32px;
  }

  .intro-right {
    /* width: auto; */
  }

  .intro-wraper {
    overflow: hidden;
  }

  .card-inner-title {
    font-size: 20px;
  }
  /* aap features section  */

  .app-features-title{
    margin-top: 3rem;
  }
  .features-card-wraper{
    margin-top: 1rem;
  }
  .feature-card {
    width: 100%;
    min-width: none;
  }
  .fc-1, .fc-2 , .fc-3 , .fc-4 , .fc-5 , .fc-6, .fc-7, .fc-8 {
    border-bottom: 1px solid #CCDCE3 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  .fc-9{
    border: 0 !important;
  }
  .all-articles-card {
    width: 100%;
  }

  .blogs-title .blogs-heading {
    font-size: 26px;
    width: 100% !important;
  }

  .about-sec2-card {
    width: 100%;
  }

  .workout2-left {
    background: none;
  }


  .trianers-iphone {
    top: -17%;
    width: 19rem !important;
  }

  .john-shek {
    left: 1%;
    top: -7%;
    width: 19rem !important;
  }

  .omar-saeed {
    left: 9%;
    bottom: 32%;
    width: 11rem !important;
  }

  .heading-48px-white {
    font-size: 30px;
  }

  .plan-txt-white {
    font-size: 20px;
  }

  .home-lifting {
    right: 12%;
    bottom: 5%;
  }

  .text-logo img {
    width: 11rem !important;
  }

  .plan-txt {
    font-size: 20px;
  }

  .workout-body-wraper {
    row-gap: 1rem;
    padding: 2rem;
  }

  .workout2-left {
    padding: 2rem;
  }

  .home-lifting {
    width: 78% !important;
  }

  .workout-slide1 {
    height: 53rem;
  }

  .recipies-grp {
    width: 100% !important;
  }

  .meal-grp {
    width: 100% !important;
  }

  img.healthy-grp {
    width: 208px !important;
  }

  .heading-64px {
    font-size: 27px;
  }

  .step-right {
    height: 52rem;
  }

  .sm-card-wraper {
    flex-direction: row !important;
    flex-wrap: wrap;
    column-gap: 8px;
    padding: 4px !important;
  }

  .blogs-side-card {
    width: 100%;
  }

  .blog-side-text {
    row-gap: 0.5rem;
  }

  .blogs-container .card-heading {
    font-size: 23px;
  }

  .blogs-container .card-mid-p {
    font-size: 17px;
  }

  .blogs-container .blogs-h2 {
    line-height: 32px;
    font-size: 24px;
  }
  .blogs-text-wraper li{
    font-size: 17px;
  }

  .blogs-h3 {
    font-size: 20px;
  }

  .blogs-list {
    font-size: 16px;
  }

  .big-card-head {
    font-size: 21px;
    line-height: 33px;
  }

  .faq-head {
    text-align: left;
    font-size: 14px !important;
  }

  .accordion {
    row-gap: 1rem;
  }

  .faq-ans {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  .big-card-head {
    font-size: 19px;
  }

  .info-vid-title h2 {
    text-align: center;
  }

  .challenge-dash {
    position: unset !important;
  }

  .plan-txt-xsm {
    font-size: 14px;
  }

  .plan-txt-sm-black {
    font-size: 15px;
  }

  .step-activities {
    bottom: -178px;
  }

 /* section margin  */
  .mt-6rem{
    margin-top: 5rem !important;
  }
}

@media screen and (max-width: 490px) {
  .blogs-logo {
    column-gap: 0.2rem;
  }

  .width-sm {
    width: 100%;
  }

  .gap-md-1rem {
    column-gap: 0;
  }

  .sub-nav .nav-link {
    font-size: 10px;
  }

  .sub-nav .nav-item img {
    display: none;
  }

  .subnav-logo {
    width: 100%;
  }

  p.subnav-title {
    display: none;
  }

  .hero-left {
    padding-right: 1rem;
    padding-left: 1rem !important;
  }

  .hero-count {
    height: 3rem;
    justify-content: space-between;
  }
  .download-stats{

  }
  .download-stats > img{
    width: calc(100% - 149px);
    max-width: 149px;
  }
  .download-stats > div{
    width: 149px;
    gap: 0 !important;
  }
  .download-stats > div h4{
    font-size: 16px;
  }
  .download-stats .hero-description{
    font-size: 13px !important;
    line-height: normal !important;
  }
  .hero-buttons-applink a.app-btn {
    width: 70%;
    height: 60px;
  }

  .hero-left-lower {
    column-gap: 0.5rem;
  }
  .hero-left-lower p{
    font-size: 12px;
    line-height: normal;
  }
  .hero-left-lower span{
    font-size: 16px;
    line-height: normal;
  }
  .hero-sec-homepg .text-logo img {
    width: 5rem !important;
  }

  .hero-buttons-applink img {
    width: 100%;
  }

  /* .log-food {
    left: 44%;
    width: 8.2rem;
    top: 22.3%;
  }

  .macro-food1 {
    margin-left: 9%;
    width: 11rem;
  } */

  .ques-p,
  .ques span {
    font-size: 18px;
  }

  .blogs-hero .subscribe-form {
    width: 100%;
    row-gap: 1rem;
    align-items: center;
  }

  .subs-email {
    width: 180px;
    padding: 5px;
    font-size: 12px;
  }

  .subs-button {
    width: 74px;
    height: 37px;
    font-size: 12px;
    padding: 4px;
  }

  /* Workout carousel section */
  .iphone-workout-plan {
    width: 17rem !important;
  }

  .muscles-plan {
    right: -10%;
    top: 23%;
    width: 12rem !important;
  }

  .plan-txt-white {
    line-height: 20px;
    font-size: 16px;
  }

  .heading-48px-white {
    line-height: 103%;
    font-size: 23px;
  }

  .workout-slide1 {
    height: auto;
  }

  .workout-body-wraper,
  .workout2-left {
    padding: 1rem;
  }

  .text-logo img {
    width: 8rem !important;
  }

  .workout-img-wraper,
  .workout-right {
    display: flex;
    justify-content: center !important;
  }

  .home-lifting {
    width: 57% !important;
  }

  .aaron-ag {
    width: 62% !important;
  }

  /* Subscription section */
  .subscribe-form {
    flex-wrap: wrap;
    row-gap: 1rem;
    justify-content: center;
  }

  /* testomonial section */
  .testo-text {
    font-size: 15px;
    letter-spacing: 0.2px;
    font-weight: 500;
    letter-spacing: 0.015em;
    text-align: start;
  }

  .testo-slide {
    padding: 1rem !important;
    border-radius: 10px !important;

  }

  .testimonial .owl-item.active {
    padding-left: 0 !important;
    padding-right: 0 !important;

  }

  .testo-aurthor {
    font-size: 14px;
    text-align: left;
  }

  .hero-sub-heading {
    font-size: 23px !important;
    line-height: 28px;
  }

  .plan-txt {
    font-size: 16px;
    line-height: 20px;
  }

  .mate-body {
    width: 340px;
    height: 320px;
  }

  .step-right {
    height: 45rem;
  }

  .about-description {
    width: 100% !important;
  }

  .card-inner-heading {
    font-size: 27px;
  }

  .about-description2 {
    width: 100% !important;
  }

  .big-card-head {
    font-size: 17px;
    line-height: 23px;
  }

  .single-blog-cards-bottom .all-articles-card {
    min-width: 19rem;
  }

  .subscribe-paragraph {
    font-size: 16px;
    line-height: 24px;
  }

  .hero-right video {
    width: 100%;
  }

  .hero-sub-heading {
    margin: 2px;
  }

  .hero-sec-homepg .text-logo img {
    width: 6rem !important;
  }

  .blogs-nav .nav-item .nav-link {
    padding: 6px 6px;
    font-size: 15px !important;
  }
  #macro-mate-section .macro-food1{
    width: 95%;
  }
  #macro-mate-section .log-food{
    width: 62%;
    top: 37px;
    left: 95px;
  }
  #workout-loging-mate-section .mate-body{
    width: 340px;
    height: 306px;
  }
  #workout-loging-mate-section .macro-food1 {
    position: absolute;
    margin-left: -72px;
    width: 95%;
    top: 0;
}
#workout-loging-mate-section .log-food {
  top: 4px;
  left: 87px;
  width: 62%;
}
#workout-loging-mate-section .mac-row1 > div{
  height: auto;
}
}

.no-bullets {
  list-style: none ! important;
}









