
#hero-section{
    margin-top: -70px;
}
#hero-section .hero-wraper{
    /* min-height: calc(100vh - 88px); */
}

.backShadow{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 204px;
    height: 204px;
    background: #3e4a79;
    filter: blur(171px);
    z-index: -1;
}
#introduction-section .intro-play-btn:hover{
    box-shadow: 0 0 180px 0px #0604ba;
}
#introduction-section video{
    outline: 0px;
    width: 100%;
    /* object-fit: cover; */
    /* padding: 2rem 0rem 2rem 0rem; */
}
#introduction-section .video-wrapper > div{
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 1rem 0rem 1rem 0rem;
}
#introduction-section .video-wrapper{
    position: relative;
}
#introduction-section .intro-thumbnail{
    width: 100%;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    object-fit: cover;
}
.ir-child{
    position: relative;
    width: 80%; 
}

.download-stats > div h4{
    font-size: 22px;
    font-weight: 600;
    font-family: 'satoshi-medium';
    color: #3148B9;
}
.hero-right{
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero-right img{
    width: 100%;
}
.hero-right.khatam::after{
    content: none !important;
}
#intro-section {
    box-shadow: 400px 0 0 #191A30, -400px 0 0 #191A30;
}

/* app features updated design  */
.app-features-title{
    margin-top: 5rem;
}
            /* ////////////// Workout performed by professionals ////////////// */

.blue-heading-text{
    color: #3E34CE !important;
}
.wpbp-img{
    position: relative;
    z-index: 260;
}
.workout-professionals::after{
    content: "";    
    position: absolute;
    width: 800px;
    height: 600px;
    border-radius: 50%;
    background: #E7EBF4;
    filter: blur(130px);
    z-index: 239;
    display: block;
    top: calc(50% - 600px / 1.8);
    bottom: -1px;
    left: calc(50% - 800px / 2);
}
.wpbp-title, .wpbp-description{
    position: relative;
    z-index: 260;
}

/* //////////////// Focused workouts /////////////// */

.focused-left{
    width: calc(65% - 12px);
}
.focused-right{
    width: calc(35% - 12px); 
}
.program-cards{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    margin-top: 1.25rem;
}
.program-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    width: calc(50% - 7.5px);
    height: 48px;
    background: #F4F6F8;
    border-radius: 40px;
    justify-content: center;
}
.program-card p{
    font-family: 'satoshi-medium'; 
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #21345A;
}
/* ////////////////// track fitness //////////////////////// */

.tf-left{
    width: calc(35% - 92px);
    margin-left: 80px;
}
.tf-left img{
    width: 100%;
}
.tf-right{
    width: calc(65% - 12px);
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}
.tf-desc{
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: justify;
    color: #21345A;
    line-height: normal;
}
/* .tf-right h5 {
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #131E34;
} */
.tf-ul-wrapper{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 8rem;
}
.tf-ul-wrapper li{
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #21345A;
}
.pulse{
    position: absolute;
    width: 280px !important;
    top: calc(60% - 280px / 2);
    left: calc(-140px);
    z-index: -1;
}


/*/////////////////////// Integrated Devices ////////////////////// */
.id-separator{
    width: 1px;
    height:48px;
    background-color: #98A2AE;
}
.integrated-devices{
    background-color:#F4F6F8 ;
    box-shadow: 400px 0 0 #F4F6F8, -400px 0 0 #F4F6F8;
    padding: 30px 0px;
}
.id-title{
    font-family: 'clash-display-semibold';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    color: #1B2B4B;
    margin: 0;
}
.id-desc{
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #21345A;
}
/*//////////////////////// fitness tracking ////////////// */

.ft-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    gap: 8px;
    border: 1px solid #98A2AE;
    border-radius: 38px;
}
.ft-title p{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1B2B4B;
    font-family: 'satoshi-medium';
    margin: 0;
}

/* Youga section  */
.yoga-body{
    margin-top: 4rem;
}
.yoga-card-wrapper{
    padding: 0px 16px 0px 0px;
    width: 25%;
}
.yc-desc{
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #131E34;
    padding: 10px;
}
.yoga-card{
    width: 100%;
}
.yoga-card img{
    width: 100%;
}
.yc1,
.yc3{
    margin-top: 5rem;
}

/* recovery workouts  */
.rws-left {
    width: calc(50% - 4px);
    display: flex;
    flex-direction: column;
    line-height: 1.3;
}
.rws-left p{
    line-height: 2;
}
.rws-right{
    width: calc(50% - 4px);
    display: flex;
    justify-content: flex-end;
}
.rws-right img{
    width: 100%;
    max-width: 588px;
    max-height: 679px;
}

    /* ???????? create customized workouts ???????  */
.cwc-body {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.cwc-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 48%;
    max-width: 514px;
}
.cwc-right {
    width: 50%;
    display: flex;
}
.cwc-right img{
    width: 100%;
    max-width: 514px;
}
.cwc-body .mate-sub-title{
font-size: 30px !important;
}
.cwc-body .mate-sub-description{
font-size: 19px !important;
}
/* testimonial carousel  */

#testimonial-section .alice-carousel__prev-btn [data-area]::after {
    background-image: url(/public/assets/chevron-left-blue.svg) !important;

}

#testimonial-section .alice-carousel__next-btn [data-area]::after {
    background-image: url(/public/assets/chevron-right-blue.svg) !important;
}
#testimonial-section .alice-carousel__next-btn {
    top: -16% !important;
    right: 5px;
    position: absolute;
    border-radius: 59px;
    width: 48px !important;
    height: 48px !important;
    background-color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #3A4555;
}
#testimonial-section .alice-carousel__prev-btn {
    top: -16% !important;
    right: 70px !important;
    position: absolute;
    border-radius: 59px;
    width: 48px !important;
    height: 48px !important;
    background-color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Frame 48097998 */
    border: 1px solid #3A4555;
    left: unset !important;
}
#testimonial-section .alice-carousel__prev-btn-item,#testimonial-section .alice-carousel__next-btn-item{
    padding: 16px !important;
}
#testimonial-section .th-left{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
#testimonial-section .th-left h5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    background: #EBECF9;
    border-radius: 8px;
    width: fit-content;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #3C2EB3;
    font-family: 'satoshi-medium';
}
#testimonial-section .alice-carousel__wrapper{
    margin-top: 2.5rem;
}
#testimonial-section .alice-carousel .alice-carousel__wrapper ul li{
    padding: 0px 10px !important;
}
.feature-card-logo img{
    margin-right: -5px;
}
@media (max-width: 1880px) {
    #hero-section .hero-wraper{
        min-height: calc(100vh - 88px);
    }
}
@media (max-width:1440px){
    .tf-desc, .tf-right h5, .tf-ul-wrapper li{
        font-size: 20px;
    }
    .pulse{
        width: 200px !important;
        top: calc(60% - 200px / 2);
        left: calc(-100px);
    }
    .rws-left p{
        line-height: normal !important;
    }
}  
@media (max-width:1200px){
    .focused-left{
        width: calc(60% - 12px); 
    }
    .focused-right {
        width: calc(40% - 12px);
    }
    #testimonial-section .th-left h5{
        font-size: 17px;
    }
}             
@media (max-width:990px){
    #hero-section{
        margin-top: unset;
    }
    .hero-wraper{
        flex-direction: column !important;
        gap: 16px !important;
    }
    .backShadow{
        width: 110px;
        height: 110px;  
    }
    .focused-workouts{
        flex-direction: column;
        align-items: center;
    }   
    .focused-left{
        width: 100%;
    } 
    .focused-right{
        width: 100%;
        margin-top: 3rem;
    } 
    .focused-right img{
        width: 387px !important; 
    }
    .program-card p{
        font-size: 14px;
    }
    .track-fitness{
        flex-direction: column-reverse;
    }
    .tf-left{
        width: calc(45%);
        margin-left: 100px;
    }
    .pulse{
        left: calc(-135px);
    }
    .tf-right{
        width: calc(100%);  
    }
    .id-title{
        font-size: 30px; 
    }
    .id-desc{
        font-size: 20px; 
    }
    .id-brands img{
        height: 28px;
    }
    /* yoga  */
    .yoga-body{
        flex-wrap: wrap;
        row-gap: 10px;  
    }
    .yoga-card-wrapper{
        padding: 0 10px 0 0;
        width: 50%;
    }
    .yc-desc{
        font-size: 16px;
    }
    .yc1,
    .yc3{
        margin-top: 0rem;
    }
    .rec-workouts-section{
        flex-direction: column;
    }
    .rws-left{
        width: 100%;
        display: flex
    ;
        flex-direction: column; 
    }
    .rws-right {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
@media (max-width:610px){
    #testimonial-section .th-left h5{
        font-size: 13px;
    }
    #testimonial-section .th-left{
        gap: 1rem;
    }
    /* #introduction-section{
        transform: scale(1.2);
    } */
    .cwc-body .mate-sub-title{
        font-size: 20px !important;
        }
        .cwc-body .mate-sub-description{
        font-size: 14px !important;
        }
}
@media (max-width:570px){
    #intro-section{
        box-shadow: 300px 0 0 #191A30, -300px 0 0 #191A30;
    }
    .id-title{
        font-size: 23px;
    }
    .id-desc {
        font-size: 16px;
    }
    .program-cards{
        gap: 5px;  
    }
    .program-card p{
        font-size: 10px;
    } 
    .program-card{
        padding: 3px 5px;  
    }
    .tf-left {
        width: calc(84% - 100px);
        margin-left: 70px;
    }
    .pulse {
        width: 140px !important;
        top: calc(60% - 190px / 2);
        left: calc(-80px);
    }
    .tf-desc, .tf-right h5, .tf-ul-wrapper li{
        font-size: 15px;
    }
    .tf-ul-wrapper{
        gap: 10px;
    }
    .id-brands img{
        height: 17px;
    }
    .id-separator{
        display: none;
    }
    .macro-mate-title {
        width: 90% !important;
    }
    /* Yoga  */
    .yoga-body{
        flex-wrap: wrap;
        row-gap: 10px;  
    }
    .yoga-card-wrapper{
        padding: 10px !important;
        width: 100%;
    }
    .yc-desc{
        font-size: 14px;
    }
    .yc1,
    .yc3{
        margin-top: 0rem;
    }
    .focused-right img{
        width: 100% !important; 
    }
    .cwc-body{
        flex-direction: column;
        gap: 1rem; 
    }
    .cwc-left{
        width: 100%;   
        order: 0;
    }
    .cwc-right{
        order: 1;
        width: 100%;
    }
}
@media (max-width:490px){
    #custom-scroll-spy{
        padding: 10px !important;
    }
}