.fade {
    opacity: 0;
}

.fade:not(.show) {
    opacity: unset;
}
.fade.show {
    animation: fadein 0.3s linear forwards;
}

@keyframes fadein {
    0%{opacity: 0;}
    100%{opacity: 1;}
}