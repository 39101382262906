.custom-accordion{
    width: calc(50% - 4px);  
    margin-top: 2rem;
    /* max-height: 35rem; */
}
.ft-carousel{
    width: calc(50% - 4px);
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 35rem;
}
 .ftc-item{
     width: 0;
    position: relative;
    opacity: 0;
    transition: 0.4s forwards linear;
    z-index: 0;
}
.ftc-item.active{
    width: 100%;
    z-index: 1;
    opacity: 1;
    /* display: block; */
}
.custom-accordion #accordion-item {
    position: relative;
    background: unset !important;
    border-bottom: unset !important;
    border-top: 2px solid #a5a5a53b !important;
    border-radius: 0 !important;
}

.custom-accordion .accordion-header {
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color:#131E34;
}
.custom-accordion .accordion-body{
font-family: 'satoshi';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #21345A;
}
.custom-accordion .accordion-header .accordion-button.collapsed{
    font-family: 'cabinetGrotesk-xbold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px !important;
    color: #131E34; 
}


.custom-accordion .accordion-header #ca-heading:not(.collapsed){
    color: #3941E9 !important;
    font-family: 'cabinetGrotesk-xbold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px !important;
}
.custom-accordion .accordion-item.active:after{
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(to right, #a5a5a53b 10%, #3941E9 100%);
    animation: borderMove 7s forwards linear;
}
.acc-img{
    width: 0;
    height: 0;
    display: none;
    visibility: hidden;
}
@keyframes borderMove {
    0%{
        width: 0;
    }
    100%{
        width: 100%;
    }
    
}

@media (max-width:1440px){
    .custom-accordion .accordion-header .accordion-button.collapsed,
    .custom-accordion .accordion-header #ca-heading:not(.collapsed){
        font-size: 20px !important;
    }
    .custom-accordion .accordion-body{
        font-size: 15px;
    }
}
@media (max-width:990px){
    .custom-accordion .accordion-header .accordion-button.collapsed,
    .custom-accordion .accordion-header #ca-heading:not(.collapsed){
        font-size: 18px !important;
    }
    .custom-accordion .accordion-body{
        font-size: 14px;
        padding: 15px 0px;
    }
    .custom-accordion .accordion-button{
        padding: 15px 0px;
    }
}
@media (max-width:700px){
    .custom-accordion{
        width: 100%;
    }
    .ft-carousel{
        width: 0;
        height: 0;
        display: none;
    }
    .acc-img{
        width: 100%;
        height: auto;
        display: block;
        visibility: visible;
        text-align: center;
    }
    .custom-accordion .accordion-header .accordion-button.collapsed,
    .custom-accordion .accordion-header #ca-heading:not(.collapsed){
        font-size: 16px !important;
    }
    .custom-accordion .accordion-body{
        font-size: 12px;
        padding: 10px 0px;
    }
    .custom-accordion .accordion-button{
        padding: 10px 0px;
    }
}
@media (max-width:570px){
}