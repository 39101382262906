.mfct-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}
.mfct-card img {
    width: 72px;
    height: 72px;
    object-fit: cover;
    border-radius: 8px;
}
.xc-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}
.mfct-card h5 {
    font-family: 'satoshi-medium';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #1B273E;
    margin: 0;
}
.x-type-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.xt-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    border: 1px solid #E6E9EF;
    border-radius: 6px;
}
.xt-card p{
    font-family: 'satoshi-medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4B596D;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0;
}
@media (max-width: 530px){
    .mfct-card img{
        width: 64px;
        height: 64px; 
    }
    .mfct-card{
        gap: 8px; 
    }
    .mfct-card img{
        width: 64px;
        height: 64px; 
    }
    .mfct-card h5{
        font-size: 16px;
        line-height: normal; 
    }
    .x-type-card{
        gap: 6px; 
    }
    .xt-card{
        gap: 6px;
        padding: 4px 6px;
    }
    .xt-card p{
        font-size: 13px;
        line-height: normal; 
    }
}