.custom-spinner:not(.active) {
  display: none;
  visibility: hidden;
}
.custom-spinner.active {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.95);
  z-index: 9999999;
}
.custom-spinner-circle {
  animation: 1.4s ease-in-out 0s infinite normal none running spin-it;
}

@keyframes spin-it {
  0% {
    stroke-dashoffset: 306;
  }

  50% {
    stroke-dasharray: 40, 134;
  }

  100% {
    stroke-dasharray: 1, 174;
    stroke-dashoffset: 132;
  }
}