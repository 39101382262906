.alice-carousel__wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
}

.alice-card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.alice-card img {
    width: 50% !important;
}

.alice-carousel__prev-btn {
    top: 43%;
    left: 0;
    position: absolute;
    border-radius: 59px;
    width: 40px !important;
    height: 40px;
    transform: translateY(-50%);
    background-color: #5e40d4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alice-carousel__next-btn {
    top: 43%;
    right: 0;
    position: absolute;
    border-radius: 59px;
    width: 40px !important;
    height: 40px;
    transform: translateY(-50%);
    background-color: #5e40d4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alice-carousel__next-btn-wrapper,
.alice-carousel__prev-btn-wrapper {
    display: flex;
    justify-content: center;
}

.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
    fill: white !important;
    /* text-transform: capitalize;
    fill: white;
    font-size: 70px;
    font-family: monospace;
    color: #ffffff;
    text-shadow: 0px 0px 3px black;
    color: #5E40D4 !important; */
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
}

.alice-carousel__prev-btn [data-area]::after {
    background-image: url(../../../public/assets/chevron-left.svg);

}

.alice-carousel__next-btn [data-area]::after {
    background-image: url(../../../public/assets/chevron-right.svg);
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
    color: #f4f4f4;
}
.alice-carousel .all-articles-card{
    min-height: 514px;
}
.alice-carousel .blog-card-readm{
    margin-top: auto;
    padding: 0px 10px;
}

.alice-carousel .alice-carousel__wrapper ul li {
    padding: 0px 6px;
}