.cursor-pointer {
    cursor: pointer;
}

@media (max-width: 1200px) and (min-width: 420px) {
    .trending-articles .inner-sm-heading {
        width: 100% !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}