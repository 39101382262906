:root {
    --bezier: ease-in-out;
    --speed: 1.2s;
    --delay: 0s;
}

.drop-shadow {
    filter: drop-shadow(0 4px 4px rgba(210, 210, 210, 0.3));
}

.vertical-carousel .content-container {
    position: relative;
}

.vertical-carousel .content-container:after {
    content: "";
    position: absolute;
    right: 0;
    left: 42.4%;
    top: 15px;
    background: #F4F4F4;
    bottom: 0;
    z-index: -1;
}

.vertical-nav {
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    z-index: 1;
}

.vertical-nav::before {
    --dashColor: #999;
    content: '';
    position: absolute;
    top: 1rem;
    bottom: 1rem;
    left: 50%;
    width: 1px;
    z-index: -1;
    background-image: linear-gradient(var(--dashColor), var(--dashColor) 75%, transparent 75%, transparent 100%);
    background-size: 1px 14px;
    border: none;
}

.vertical-nav-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    background-color: white;
    padding: 4px;
}

.custom-dot {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f1f1f1;
    flex-shrink: 0;
    transition: background 0.5s ease-in-out;
}

.vertical-nav-title {
    font-family: "satoshi", sans-serif;
    line-height: 1;
    font-size: 1.3rem;
    color: #f1f1f1;
    transition: all 0.5s ease-in-out;
}

.vertical-nav-item.active .custom-dot {
    background-color: #4F30CC;
}

.vertical-nav-item.active .vertical-nav-title {
    font-weight: bold;
    color: black;
}

.vertical-carousel-content {
    display: none;
}

.vertical-carousel-content.active {
    display: block;
    /* background-color: red; */
}

.animate-section .vertical-carousel-content.slide-out {
    /* background-color: red; */
    opacity: 1;
    animation: fadeout 0.8s var(--bezier) forwards;
}

.leave-animation .vertical-carousel-content.slide-out {
    opacity: 1;
    animation: fadeout 0.8s var(--bezier) forwards;
}

.grp-pic-sm {
    display: none;
}

.step3-img-sm {
    display: none;
}


/* 
Animations     */
section:not(.animate-section) .vertical-carousel-content .slide-top {
    animation: none !important;
}

.animate-section .vertical-carousel-content.slide-in .slide-top {
    animation: slide-top 1s ease;
}

.animate-section .vertical-carousel-content.slide-in .slide-left {
    animation: slide-left 1s ease;
}

.animate-section .vertical-carousel-content.slide-in .slide-right {
    animation: slide-right 1s ease;
}

.animate-section .vertical-carousel-content.slide-in .slide-bottom {
    animation: slide-bottom 1s ease;
}


/* .owl-stage-outer .owl-item:not(.active) {
    animation: fadeout 0.8s var(--bezier) forwards;
    display: none;
} */
section:not(.animate-section) .slide-right {
    transform: translateX(-200%);
    transition: transform 1s ease-in-out;
}

section:not(.animate-section) .slide-right {
    transform: translateX(-200%);
    transition: transform 1s ease-in-out;
}

section:not(.animate-section) .slide-left {
    transform: translateX(200%);
    transition: transform 1s ease-in-out;
}

section:not(.animate-section) .slide-bottom {
    transform: translateY(-200%);
    transition: transform 1s ease-in-out;
}

section:not(.animate-section) .slide-top {
    transform: translateY(200%);
    transition: all 1s ease-in-out;
}

/* .vertical-carousel-content.active .slide-right,
.vertical-carousel-content.active .slide-left,
.vertical-carousel-content.slide-in .slide-bottom,
.vertical-carousel-content.slide-in .slide-top {
    transition: all 1s ease-in-out;
    transform: translateX(0);
} */


section.animate-section .slide-in .slide-top,
section.animate-section .slide-top {
    transition: all 1s ease-in-out;
    transform: translateY(0);
}

section.animate-section .slide-in .slide-bottom,
section.animate-section .slide-bottom {
    transition: all 1s ease-in-out;
    transform: translateY(0);
}

section.animate-section .slide-in .slide-left,
section.animate-section .slide-left {
    transition: all 1s ease-in-out;
    transform: translateX(0);
}

section.animate-section .slide-right {
    transition: all 1s ease-in-out;
    transform: translateX(0);
}

section.animate-section .slide-right-modified {
    transition: all 1s ease-in-out;
    transform: translateX(19%) !important;
}

/*  animation slide-top * ---------------------------------------- */
@keyframes slide-top {
    0% {
        opacity: 0;
        transform: translateY(200%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-bottom {
    0% {
        opacity: 0;
        transform: translateY(-200%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-left {
    0% {
        opacity: 0;
        transform: translateX(200%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-right {
    0% {
        opacity: 0;
        transform: translateX(-200%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



.stepper-right {
    background: #F4F4F4;
    min-height: 39rem;
    overflow: hidden;
}

.stepper2-right {
    background: #F4F4F4;
    min-height: 40rem;
    overflow: hidden;
}

.stepper3-right {
    background: #F4F4F4;
    min-height: 43rem;
    overflow: hidden;
}

.q-mark {
    right: 42px;
    top: 33px;
}

.details-list {
    width: 75%;
    left: 1rem;
}

.details-list2 {
    width: 52%;
    right: 2rem;
    bottom: -3rem;
}

@media (max-width: 1440px) {
    .details-list {
        width: 70%;
    }

    .details-list2 {
        width: 45%;
        right: 3rem;
        bottom: -2rem;
    }

    .stepper-right {
        min-height: 36rem;
    }
}

.stepper-row-line {
    width: 223px;
    height: 0px;
    border: 3px solid #4F4D9D;
    border-radius: 20px;
}

.s2-body-wraper {
    min-height: 40rem;
}

.eat2fit {
    top: -36px;
}

.work2fit {
    bottom: -27px;
    right: 18px;
}

.diet2fit {
    right: 110px;
    top: 31px;
}

.warm2fit {
    bottom: 21px;
    left: 57px;
}

.daily-goals {
    bottom: -629px;
    left: 282px;
}

.step-chart {
    right: 100px;
    top: 81px;
}

.calories-detail {
    top: -15px;
    left: 15px;
}

.hero-right {
    position: relative;
    display: flex;
}



.hero-right:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    background: #fff;
    z-index: 2;
}

#custom-scroll-spy section {
    overflow: hidden;
    width: 100%;
}

#custom-scroll-spy .intro-section {
    overflow: visible;
}

@media (max-width: 1200px) {

    /* stepper  */
    /* .stepper-right,
    .s2-body-wraper,
    .stepper3-right {
        min-height: auto;
    } */

    .hero-sub-heading {
        font-size: 36px;
    }

    .stepper-left p,
    .stepper2-left p,
    .stepper3-left p {
        padding-top: 2rem;
        margin-top: auto;
    }

    .stepper3-body,
    .stepper2-body,
    .stepper1-body {
        flex-direction: column;
        row-gap: 4rem;

    }

    .vertical-carousel .content-container:after {
        display: none;
    }

    .vertical-carousel {
        display: flex !important;
        width: 100% !important;
        flex-direction: row !important;
        column-gap: 3rem !important;
        flex-wrap: nowrap !important;
    }


    .stepper-left,
    .stepper2-left,
    .stepper3-left {
        height: 28rem;
    }
}

@media (max-width: 767px) {
    .grp-pic-sm {
        display: block;
    }

    .step3-img-sm {
        display: block;
    }

    .eat2fit,
    .work2fit,
    .warm2fit,
    .diet2fit {
        display: none;
    }

    .daily-goals,
    .step-chart,
    .calories-detail {
        display: none;
    }

    .stepper-right,
    .stepper2-right,
    .stepper3-right {
        min-height: auto;
    }
}

@media (max-width: 610px) {
    .vertical-nav {
        height: 21rem;
    }

    .css-mhc70k-MuiGrid-root>.MuiGrid-item {
        padding-left: 0px;
    }

    .stepper3-body,
    .stepper2-body,
    .stepper1-body {
        row-gap: 2rem;
    }

    .stepper-left,
    .stepper2-left,
    .stepper3-left {
        height: 21rem;
    }

    .hero-sub-heading {
        font-size: 23px !important;
        line-height: 28px;
    }

    .details-list2 {
        width: 45%;
        right: 2rem;
        bottom: -0.8rem;
    }
}

@media (max-width: 490px) {
    .custom-dot {
        width: 10px;
        height: 10px;
    }

    .vertical-nav-title {
        font-size: 1rem;
    }
    .stepper-row-line{
        width: 100%; 
    }
}