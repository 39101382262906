.nav-logo {
  height: 45px;
  width: auto;
  display: block;
}

/* .nav-logo-sm {
  display: none;
} */

.navs-container.active .hidden-subnav {
  display: none;
}

.navs-container.active {
  position: sticky;
  top: 0;
  z-index: 999;
}

.main-logo-text {
  font-family: 'cabinetGrotesk-xbold';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #37395D;
  margin: 0;
}

.nav-link {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: #757575;
}

.nav-item .current.active,
.blogs-nav-item.current {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  color: #4F30CC !important;
  position: relative;
}

.nav-item .current.active::after,
.blogs-nav-item.current::after {
  content: "";
  position: absolute;
  bottom: 8%;
  left: 0%;
  background: #6F73BC;
  border-radius: 20px;
  height: 3px;
  width: 100%;
}

.sub-nav {
  background-color: #37395D;
}

.Title {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 1em;
  margin-bottom: 2em;
  background-color: rgb(192, 45, 26);
  color: #fff;
}

header {
  position: sticky;
  top: 0;
  z-index: 9999999;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
}

.menu-wraper {
  min-height: 5rem;
  box-sizing: border-box;
  padding: 16px 10px 7px 32px;
  min-height: 64px;
  background: #FFFFFF;
  /* border-bottom: 1px solid #555555;
  box-shadow: 0px 128px 51px rgba(176, 176, 176, 0.01), 0px 72px 43px rgba(176, 176, 176, 0.05), 0px 32px 32px rgba(176, 176, 176, 0.09), 0px 8px 18px rgba(176, 176, 176, 0.1), 0px 0px 0px rgba(176, 176, 176, 0.1);
  border-radius: 60px; */
}

.download-buttonH a {
  background: #010102;
  border-radius: 120px;
}

.download-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
}

.free-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  opacity: 0.7;
}

.menu-font {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #2C2C2C;
  opacity: 0.6;
}

.menu-font.current.active,
.blogs-nav-item.current {
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  .menu-wraper .navbar-nav {
    column-gap: 0.3rem !important;
  }

}

@media screen and (max-width: 1096px) {
  .main-logo-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 991px) {
  .main-logo-text {
    font-size: 20px;
  }
}

@media screen and (max-width: 490px) {
  .main-logo-text {
    display: none;
  }
}