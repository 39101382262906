footer {
    background-color: #191A30;
    /* height: 570px; */
    padding: 3rem 0rem;
}

.footer-logo {
    Width: 61.77px;
    height: 61.77px;
}

.footer-heading {
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 0;
    letter-spacing: 2.1px;
}

.footer-subheading {
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF !important;
    opacity: unset !important;
    letter-spacing: 1.1px;
}

.opacity-remove {
    opacity: 11 !important;
}

.footer-description {
    font-family: 'satoshi-light';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 0;
}

.footer-right ul {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.footer-right li {
    list-style-type: none;
    font-family: 'satoshi-light';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 0.7;
    width: 194px;
}

.footer-right a {
    list-style-type: none;
    font-family: 'satoshi-light';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    width: 194px;
    text-decoration: none;
    white-space: nowrap;
}

.follow-us {
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
}

.footer-copyrights {
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: end;
}
.footer-adress{
    font-family: 'satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffffa1;
    text-align: end;
}
.footer-social {
    column-gap: 2rem;
}


/* Footer Responsiveness */

@media screen and (max-width: 1200px) {
    .footer-right li {
        width: 156px;

    }

    .footer-right {
        flex-wrap: wrap;

    }
}

@media screen and (max-width: 1200px) {
    .footer-bottom {
        flex-wrap: wrap;
        row-gap: 5rem;
    }

    .footer-top {
        flex-wrap: wrap;
        row-gap: 4rem;
    }

    /* .fbottom-left {
        width: 100%;
    } */

    .footer-right {
        justify-content: space-between;
    }

    /* .footer-copyrights {
        text-align: center;
    } */

    footer {
        padding: 2rem 1rem 4rem 1rem;
    }
}


@media screen and (max-width: 992px) {
    .footer-right li{
        width: auto;
    }
}

@media screen and (max-width: 610px) {
    .footer-left {
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
    }

    .footer-copyrights {
        text-align: center;
    }

    .fbottom-left {
        width: 100%;
    }
    .footer-right ul{
        padding: 0;
    }
    .footer-top {
        justify-content: center;
    }
    .footer-social {
        flex-wrap: wrap;
        row-gap: 1rem;
    }
}

@media screen and (max-width: 490px) {
    
    .footer-subheading{
        font-size: 22px;
    }
    .footer-right a{
        font-size: 16px;
    }
    .footer-top{
        padding: 0 !important;
    }


   
}

.mt-5rem {
    margin-top: 5rem;
}

.mt-4rem {
    margin-top: 4rem;
}

.footer-social img {
    transition: filter 0.3s ease-in-out;
}
.footer-social img:hover {
    filter: drop-shadow(0px 0px 0px white);
}