.px-responsive-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
.ceo-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 4rem;
}
.ceo-img h4{
    font-size: 20px;
    font-family: 'satoshi-medium'; 
    color: #21345A;
}
.ceo-img img{
    border-radius: 10px;
}
.ceo-img p{
    color: #21345A;
    font-size: 15px;
    font-weight: 500;
}
@media (max-width:1200px){
    .ceo-img h4{
        font-size: 18px;
    }
    .ceo-img{
        margin-top: 2rem; 
        gap: 0;
    }
    .ceo-img p{
        font-size: 14px;
    }
}
@media (max-width:600px){
    .ceo-img h4{
        font-size: 15px;
    }
    .ceo-img{
        margin-top: 2rem; 
        gap: 0;
    }
    .ceo-img p{
        font-size: 12px;
    }
}
@media (max-width: 768px) {
    .px-responsive-5 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}